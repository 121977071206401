<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n({})
const localeLink = useLocalePath()
</script>

<template>
  <div class="relative z-20 mt-20 flex w-screen flex-col items-center gap-12 overflow-hidden px-2 py-[10vw] md:px-20">
    <h1
      class="title title-font text-secondary w-full text-center text-4xl font-bold sm:text-5xl md:text-7xl animate-animated animate-zoom-in-down"
      style="text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;"
    >
      {{ t('home.title') }}
    </h1>
    <div
      class="subtitle text-center text-2xl font-bold"
      v-html="t('home.subtitle')"
    />
    <div class="buttons flex max-w-max flex-col gap-4 md:flex-row">
      <NuxtLinkLocale
        to="/products/garden/all"
      >
        <PrimeButton outlined class="bg-white">
          {{ t('home.discover_assortment') }}
        </PrimeButton>
      </NuxtLinkLocale>
      <PaintButton
        :button-text="t('home.give_advice')"
        @button-clicked="navigateTo(localeLink('/advice'))"
      />
    </div>
    <div class="animate-animated animate-infinite animate-slow absolute left-40 bottom-20 hidden h-80 animate-bounce border-current md:left-20 md:block">
      <img src="~/assets/images/home/left-arrow.svg" alt="Arrow">
    </div>
  </div>

  <div class="md:h-30rem 2xl:h-50rem top-30vh right-0 bottom-28 -z-20 flex justify-center md:absolute ">
    <img
      class="-z-40 h-full max-w-max"
      src="~/assets/images/home/buddy.svg"
      alt=""
    >
  </div>

  <div
    class="max-w-screen md:h-80rem absolute top-0 flex -z-10 h-screen md:right-0"
  >
    <div class="-z-10 h-full w-full overflow-hidden">
      <svg
        height="100%"
        version="1.1"
        viewBox="0 0 736 1050"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            id="linearGradient-1"
            x1="66.0705874%"
            x2="66.0705874%"
            y1="4.00688724%"
            y2="55.2654083%"
          >
            <stop
              offset="0%"
              stop-color="#DC7818"
              stop-opacity="0.214802775"
            />
            <stop
              offset="100%"
              stop-color="#DC7818"
            />
          </linearGradient>
        </defs>
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="none"
          stroke-width="1"
        >
          <g
            id="Home-"
            stroke="url(#linearGradient-1)"
            stroke-width="2"
            transform="translate(-704.000000, 0.000000)"
          >
            <path
              id="Stroke-1"
              class="path"
              d="M1075.5,-17.5 C1075.5,-17.5 816.930697,357.661235 925.5,399.116941 C1010,431.382111 1039.86811,314.680035 968.044111,263.62137 C948.218111,249.526355 925.402111,244.91225 903.202111,249.422066 C857.033111,258.801361 739.064,272.717415 709,423.457332 C679.515,571.290537 823.620111,696.523651 891.291111,741.530899 C928.304111,766.148374 971.378111,770.035132 1010.05611,750.518443 C1046.45811,732.150266 1078.24711,694.28813 1056.21311,616.250788 C1055.72011,614.505958 1055.23111,612.758454 1054.76811,611.000254 C1051.30411,597.850522 1029.99311,525.315076 980.260111,520.626097 C954.743111,518.220772 930.297111,534.51922 915.375111,562.298781 C895.080111,600.078021 841.045719,669.457745 883.627111,781.875908 C926.208503,894.294071 978.451462,917.679256 1064.5,956 C1150.54854,994.320744 1252.5,978.274968 1256,947.707965 C1259.5,917.140961 1220.37226,915.87765 1221.5,944.877686 C1222.62774,973.877723 1330.22378,1025.48332 1445.5,1050"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-font {
  font-family: interstate, sans-serif;
  font-style: normal;
 }
.path {
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
